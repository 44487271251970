
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.esmp-links {

  .block__row {
    align-items: center;
    margin-bottom: 16px;
  }

  .block__title {
    margin-bottom: 0;
  }

  &__list {
    margin-bottom: 16px;
  }

  .link {
    border: 1px solid $color-white-status;
    border-radius: $base-border-radius;
    margin-bottom: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;

    &__inner {
      width: 100%;
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      align-self: flex-end;
    }

    &__switch {
      margin-right: 8px;
    }

    &__image {
      img {
        height: 53px;
      }
    }

    &__title {
      margin-right: 16px;
    }

    &__options {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &__option {
      width: 100%;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
